// Borrowed from https://decipher.dev/30-seconds-of-typescript/docs/pluralize/
export const pluralize = (num: number, word: string, plural = word + "s") =>
    [1, -1].includes(Number(num)) ? word : plural;// Create our number formatter.

// Borrowed from https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings
export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

// Borrowed from https://stackblitz.com/edit/typescript-encode-decode-base64?file=index.ts
export class EncodeDecode {

    static b64EncodeUnicode(str: string) {
        // first we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            // function toSolidBytes(match, p1) {
            (match, p1) => {
                // console.debug('match: ' + match);
                return String.fromCharCode(("0x" + p1) as any);
            }));
    }

    static b64DecodeUnicode(str: string) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

}

// Borrowed from https://bobbyhadz.com/blog/typescript-trim-whitespace-from-string#:~:text=Use%20the%20replace()%20method,string%20with%20all%20whitespace%20removed.
export function withoutAnyWhitespace(str: string) {
    return str.replace(/\s/g, '');
}
