import React, {Component} from 'react';
import {copy} from 'clipboard'
import ReactTooltip from "react-tooltip-rc";

import ClippyImg from '../img/clippy.svg';
import './clipboardCopyText.css';

type ClipboardCopyTextProps = {
    text: string;
    kk: string;
};
type ClipboardCopyTextState = {};

class ClipboardCopyText extends Component<ClipboardCopyTextProps, ClipboardCopyTextState> {
    constructor(props: ClipboardCopyTextProps, state: ClipboardCopyTextState) {
        super(props, state);
        this.state = {};
    }


    render() {
        return <>
            <input className={'clipboard-text'} id={this.props.kk} value={this.props.text} readOnly/>
            <button className={"copy-button"}
                    data-tip={'Copied!'}
                    data-clipboard-target={`#${this.props.kk}`}
                    onClick={
                        () => {
                            // Click activates the tooltip automatically.

                            // Fill the clipboard.
                            copy(this.props.text);

                            // Deactivate the tooltip soon after it appears.
                            setTimeout(() => {
                                ReactTooltip.hide();
                            }, 3000)
                        }}>
                <img className={'clippy'} src={ClippyImg} alt="Copy to clipboard"/>
            </button>
            <ReactTooltip type={'success'}
                          place={'left'}
                          effect={'solid'}
                          event={'click'}
                // delayHide={1000}
                          eventOff={'delay'}
                          isCapture={true}
                // delayHide={1000}
                //           delayHide={1000}
                // border={true}
            />
        </>;

        // return <ClipboardText target={this.props.text}>
        //     <p>{this.props.text}</p>
        // </ClipboardText>;
    }
}

export default ClipboardCopyText;
