import GoogleForm from 'google-form-send';
import {monthlyDues} from "./monthly-income";
import {withoutAnyWhitespace} from "./util";
import {getDuesOwed, getReceiptCode, getTotalOwed} from "./form-calculations";
import {FormValues} from "./form-values";


export function attemptSendForm(vals: FormValues) {
    const formUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSflcBDlI_cp6tTVb9_jqGFgfLXzpauhzKgf5S8JWOQZ7i58Cw';
    var form = new GoogleForm(formUrl);

    // If these need to be updated, edit the source form, go to 3 vertical dots, "Get pre-filled link",
    // fill out something in each input, get the link and inspect it for the names of the fields.

    // https://docs.google.com/forms/d/e/1FAIpQLSflcBDlI_cp6tTVb9_jqGFgfLXzpauhzKgf5S8JWOQZ7i58Cw/viewform?usp=pp_url&entry.791033872=2023+assess
    // entry.714990817=Income+Range&
    // entry.1534966854=Dues+Periods&

    // entry.1250366275=Dues+Rate&
    // entry.545203792=Dues+Paid+This+Submission

    // entry.1703184958=given&
    // entry.1709842591=family
    // entry.101372271=club&
    // entry.1102501295=email&
    // entry.791734514=address&
    // entry.1281474078=unit&
    // entry.1512382838=city&
    // entry.806269376=province&
    // entry.660812935=postal&
    // entry.714990817=income&
    // entry.1534966854=dues&
    // entry.285963139=org+fund&
    // entry.539141565=spring+drivce&
    // entry.1151245909=Other+Contribution+Description&
    // entry.402336604=Other+Contribution+($)
    // entry.1912155794=convention+asse&
    // entry.791033872=2023+assess
    // entry.945175419=initiation&
    // entry.790993845=total&
    // entry.1443898088=receipt

    form.addField('entry.1703184958', vals.givenName);
    form.addField('entry.1709842591', vals.familyName);
    form.addField('entry.101372271', vals.club);
    form.addField('entry.1102501295', vals.email);
    form.addField('entry.791734514', vals.address);
    form.addField('entry.1281474078', vals.unitNumber);
    form.addField('entry.1512382838', vals.city);
    const province = vals.province.toUpperCase();
    form.addField('entry.806269376', province);
    form.addField('entry.660812935', withoutAnyWhitespace(vals.postalCode));

    // This is because the valid enum values vary from 2-11 so it's fine to just use the raw modified value here.
    // These are not tracked for Quebec members.
    if (province !== 'QC') {
        form.addField('entry.714990817', vals.income - 1);
        form.addField('entry.1250366275', monthlyDues(vals.income));
        form.addField('entry.1534966854', +vals.duesPeriod);
        form.addField('entry.545203792', getDuesOwed(vals));
    }

    // Fields for non-dues funding.
    form.addField('entry.285963139', vals.fundSelection === 'Org Fund' ? +vals.donation : '0');
    form.addField('entry.539141565', vals.fundSelection === 'Spring Drive' ? +vals.donation : '0');
    form.addField('entry.1151245909', vals.fundSelection === 'Other' ? vals.otherFundDescription : '');
    form.addField('entry.402336604', vals.fundSelection === 'Other' ? +vals.donation : '0');

    form.addField('entry.1912155794', vals.conventionAssessmentPreviousYear ? monthlyDues(vals.income) : 0);
    form.addField('entry.791033872', vals.conventionAssessmentCurrentYear ? monthlyDues(vals.income) : 0);
    form.addField('entry.945175419', vals.initiation ? 1 : 0);
    form.addField('entry.790993845', getTotalOwed(vals));
    form.addField('entry.1443898088', getReceiptCode(vals));


    // As explained here, https://github.com/francisconeves97/react-google-forms-hooks/blob/ca5018e578cfb0e230f9be58dfeee4117db28160/src/hooks/useGoogleForm.ts#L61-L65,
    // We cannot get any verification across sites that the form submission has succeeded. Maybe it failed! This is not ideal!
    try {
        console.log(`Sending form with 'google-form-send'.`);
        form.send();
    } catch (e) {
        console.log(e)

        console.log(`Caught and ignored exception ${e}`);

        // This type of error should be OK...
        // Caught and ignored exception NetworkError: Failed to execute 'send' on 'XMLHttpRequest': Failed to load 'https://docs.google.com/forms/d/e/1FAIpQLSflcBDlI_cp6tTVb9_jqGFgfLXzpauhzKgf5S8JWOQZ7i58Cw/formResponse?ifq&entry.1703184958=David%20Kordalewski&entry.101372271=fdsf&entry.791734514=118%20Christie%20St.&entry.1281474078=5&entry.1512382838=Toronto&entry.660812935=M6G%203B2&entry.714990817=5&entry.1534966854=&entry.1177924865=&entry.1912155794=&entry.945175419=&entry.790993845=&entry.1443898088=NOT%20YET%20IMPLEMENTED...&submit=Submit'.

        // We should fail on net::ERR_CONNECTION_TIMED_OUT, since the request certainly didn't get through in that case.
        // But it displays the same error. I think the XHTTPRequest underlying the send may be masking our ability to
        // tell the difference at this point in the code.
        // Caught and ignored exception NetworkError: Failed to execute 'send' on 'XMLHttpRequest': Failed to load 'https://docs.google.com/forms/d/e/1FAIpQLSflcBDlI_cp6tTVb9_jqGFgfLXzpauhzKgf5S8JWOQZ7i58Cw/formResponse?ifq&entry.1703184958=fd&entry.101372271=fff&entry.791734514=dd&entry.1281474078=dd&entry.1512382838=dd&entry.660812935=d&entry.714990817=3&entry.1534966854=2&entry.1177924865=&entry.1912155794=8&entry.945175419=&entry.790993845=24&entry.1443898088=NOT%20YET%20IMPLEMENTED...&submit=Submit'.

    }
}