import {MonthlyIncomeAmount} from "./monthly-income";

export interface FormValues {
    givenName: string;
    familyName: string;
    club: string;
    email: string;
    address: string;
    unitNumber: string;
    city: string;
    province: string;
    postalCode: string;
    income: MonthlyIncomeAmount;
    duesPeriod: number;
    donation: number;
    fundSelection: string;
    otherFundDescription: string;
    conventionAssessmentPreviousYear: boolean;
    conventionAssessmentCurrentYear: boolean;
    initiation: boolean;
}

export const requiredIdentityFieldNames = [
    'givenName', 'familyName', 'club', 'email', 'address', 'city', 'province', 'postalCode',
] as (keyof FormValues)[];
