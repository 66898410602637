import React, {Component} from 'react';
import ClipboardCopyText from "./components/clipboardCopyText";
import './css/dues-form.css';

type FinishedPageProps = {
    totalOwed: string,
    commentLine: string,

}

class FinishedPage extends Component<FinishedPageProps> {
    render() {
        return (
            <div className={'final-instructions'}>
                {/*<p className={'thank-you'}>Thank you, comrade.</p>*/}
                {/*<p>To complete payment, please send us an interac transfer from your banking*/}
                {/*    institution with the following details</p>*/}
                <p>Thank you for submitting the information for your payment. To complete the payment, please go to your
                    online banking institution and send an interac transfer to the destination below and include the
                    following information:</p>

                <table className={'details-table'}>
                    <tbody>
                    <tr>
                        <td className={'details-type'}>Destination</td>
                        <td className={'details-content'}>
                            <ClipboardCopyText text={'cpcpccdonation@gmail.com'} kk={'email'}/>
                        </td>
                    </tr>
                    <tr>
                        <td className={'details-type'}>Amount</td>
                        <td className={'details-content'}>
                            <ClipboardCopyText text={this.props.totalOwed} kk={'amount'}/>
                        </td>
                    </tr>
                    <tr>
                        <td className={'details-type'}>Password</td>
                        <td className={'details-content'}>
                            <ClipboardCopyText text={'cpc1921'} kk={'password'}/>

                        </td>
                    </tr>
                    <tr>
                        <td className={'details-type'}>Comment Line</td>
                        <td className={'details-content'}>
                            <ClipboardCopyText text={this.props.commentLine} kk={'commentLine'}/>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <p>For your convenience, here are links to the portals of some major banking
                    institutions, probably including yours.</p>
                <div className="banklist">
                    <p><a
                        href="https://www.alterna.ca/Personal/EverydayBanking/Access/OnlineBanking/InteracETransfer/ "
                        target="_blank" rel="noopener noreferrer">Alterna
                        Savings</a></p>
                    <p><a
                        href="https://www.bmo.com/main/personal/ways-to-bank/digital-banking/#transfer-money "
                        target="_blank" rel="noopener noreferrer">Bank
                        of Montreal</a></p>
                    <p><a
                        href="https://www.cibc.com/en/personal-banking/ways-to-bank/how-to/send-interac-e-transfer.html "
                        target="_blank" rel="noopener noreferrer">CIBC</a>
                    </p>
                    <p><a
                        href="https://www.desjardins.com/ca/personal/accounts-services/ways-to-bank/online/transfer-money-between-accounts/index.jsp "
                        target="_blank" rel="noopener noreferrer">Desjardins</a>
                    </p>
                    <p><a href="https://www.hsbc.ca/ " target="_blank" rel="noopener noreferrer">HSBC</a></p>
                    <p><a href="https://www.banquelaurentienne.ca/en/index.sn " target="_blank"
                          rel="noopener noreferrer">Laurentian Bank</a>
                    </p>
                    <p><a href="https://www.meridiancu.ca/personal " target="_blank" rel="noopener noreferrer">Meridian
                        Credit Union</a></p>
                    <p><a href="https://www.nbc.ca/ " target="_blank" rel="noopener noreferrer">National Bank of
                        Canada</a></p>
                    <p><a href="https://www.rbcroyalbank.com/personal.html " target="_blank" rel="noopener noreferrer">Royal
                        Bank of Canada</a>
                    </p>
                    <p><a href="https://www.scotiabank.com/ca/en/personal.html " target="_blank"
                          rel="noopener noreferrer">Scotiabank of
                        Canada</a></p>
                    <p><a href="https://www.tangerine.ca/ " target="_blank" rel="noopener noreferrer">Tangerine</a></p>
                    <p><a href="https://www.td.com/ca/en/personal-banking/ " target="_blank" rel="noopener noreferrer">TD
                        Canada Trust</a>
                    </p>
                </div>

            </div>
        );
    }
}

export default FinishedPage;