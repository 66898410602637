import React from 'react';
import './css/App.css';
import DuesForm from "./dues-form";


function App() {

    return (
        <div className="App">

            <header className="App-header"/>

            <h1>Dues Payment Form</h1>
            {/*<p>DK: WORK IN PROGRESS...</p>*/}

            <DuesForm/>

        </div>
    );
}

export default App;
