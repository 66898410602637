import {useField, useFormikContext} from "formik";
import React from 'react';

export const OtherFundField = (props) => {
  const {
    values: {fundSelection},
    // touched,
    // setFieldValue,
  } = useFormikContext();
  const [field] = useField(props);

  React.useEffect(() => {
    // set the value of textC, based on textA and textB
    if (false
      // textA.trim() !== '' &&
      // textB.trim() !== '' &&
      // touched.textA &&
      // touched.textB
    ) {
      // setFieldValue(props.name, `textA: ${textA}, textB: ${textB}`);
    }
  }, [fundSelection, props.name]);

  const classes =
    [props.class,
      fundSelection === 'Other' ? '' : 'hidden-field'].join(' ');


  return (
    <>
      <input {...props} {...field}
             disabled={fundSelection != 'Other'}
             className={classes}/>
      {/*{!!meta.touched && !!meta.error && <div>{meta.error}</div>}*/}
    </>
  );
};
