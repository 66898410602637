export enum MonthlyIncomeAmount {
    UNSPECIFIED = 1,
    UNDER_600,
    BETWEEN_601_AND_1000,
    BETWEEN_1001_AND_1500,
    BETWEEN_1501_AND_2000,
    BETWEEN_2001_AND_2500,
    BETWEEN_2501_AND_3000,
    BETWEEN_3001_AND_3500,
    BETWEEN_3501_AND_4000,
    BETWEEN_4001_AND_4500,
    OVER_4500,
}

type MonthlyIncomeOption = {value : string, description: string};

var monthlyIncomeOptions : Array<MonthlyIncomeOption>= [
    {value: '1', description: 'Select Your Dues Amount'},
    {value: '2', description: '$1 (Monthly Income Under $600)'},
    {value: '3', description: '$4 (Monthly Income Between $601 and $1000)'},
    {value: '4', description: '$8 (Monthly Income Between $1001 and $1500)'},
    {value: '5', description: '$12 (Monthly Income Between $1501 and $2000)'},
    {value: '6', description: '$16 (Monthly Income Between $2001 and $2500)'},
    {value: '7', description: '$20 (Monthly Income Between $2501 and $3000)'},
    {value: '8', description: '$24 (Monthly Income Between $3001 and $3500)'},
    {value: '9', description: '$28 (Monthly Income Between $3501 and $4000)'},
    {value: '10', description: '$34 (Monthly Income Between $4001 and $4500)'},
    {value: '11', description: '$40 (Monthly Income Over $4500)'},
];

export function monthlyDues(monthlyIncomeAmount: MonthlyIncomeAmount) {
    switch (+monthlyIncomeAmount) {
        case MonthlyIncomeAmount.UNDER_600:
            return 1;
        case MonthlyIncomeAmount.BETWEEN_601_AND_1000:
            return 4;
        case MonthlyIncomeAmount.BETWEEN_1001_AND_1500:
            return 8;
        case MonthlyIncomeAmount.BETWEEN_1501_AND_2000:
            return 12;
        case MonthlyIncomeAmount.BETWEEN_2001_AND_2500:
            return 16;
        case MonthlyIncomeAmount.BETWEEN_2501_AND_3000:
            return 20;
        case MonthlyIncomeAmount.BETWEEN_3001_AND_3500:
            return 24;
        case MonthlyIncomeAmount.BETWEEN_3501_AND_4000:
            return 28;
        case MonthlyIncomeAmount.BETWEEN_4001_AND_4500:
            return 34;
        case MonthlyIncomeAmount.OVER_4500:
            return 40;
        case MonthlyIncomeAmount.UNSPECIFIED:
            return 0;
        default:
            console.error(`monthlyDues ERROR : ${monthlyIncomeAmount}`);
            // alert(`monthlyDues error : ${monthlyIncomeAmount}`);
            return -500;
    }
}

export {monthlyIncomeOptions};
