import React, {Component} from 'react';
import {Field, Form, Formik, FormikErrors, FormikHelpers, FormikTouched} from 'formik';
import * as Yup from 'yup';
import {MonthlyIncomeAmount, monthlyIncomeOptions} from "./monthly-income";
import {attemptSendForm} from "./send-form";
import InfoButton from "./components/infoButton";
import {currencyFormatter, EncodeDecode, pluralize} from "./util";
import {OtherFundField} from "./components/otherFundField";
import FinishedPage from "./finished-page";
import {getReceiptCode, getTotalOwed} from "./form-calculations";
import {FormValues, requiredIdentityFieldNames} from "./form-values";
import './css/dues-form.css';
import './css/dues-form-mobile.css';

type DuesFormProps = {};
type DuesFormState = {
    totalOwed: string;
    submissionComplete: boolean;
    commentLine: string;
};

const defaultStringValidation = Yup.string().defined('Required').max(130, "Your response is too long.");

const provinceCodes = ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];
// const provinceCodes = ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'SK', 'YT'];
const validationSchema = Yup.object({
    givenName: defaultStringValidation,
    familyName: defaultStringValidation,
    club: defaultStringValidation,
    email: defaultStringValidation.email('Invalid email address'),
    address: defaultStringValidation,
    unitNumber: Yup.string().optional().max(130, "Your response is too long."),
    city: defaultStringValidation,
    // Province codes from https://www.ncbi.nlm.nih.gov/books/NBK7254/
    province: defaultStringValidation.uppercase().length(2, "Please use the 2-letter code.")
        .oneOf(provinceCodes, 'Invalid province code.'),
    postalCode: defaultStringValidation,
    // We don't bother getting the income from QC members, and so we don't bother validating it.
    income: Yup.number().when('province', {
        is: (p: string) => p && p.toUpperCase() === 'QC',
        then: (schema) => schema,
        otherwise: (schema) => schema.defined().min(2, 'Required'),
    }),
    duesPeriod: Yup.number().defined().min(0).max(3).default(3),
    donation: Yup.number().typeError('Must be a number.').default(0).min(0, "Haha. Sorry, no."),
    fundSelection: Yup.string().default('Org Fund'),
    // This says that it's required when 'Other' is the fundSelection and doesn't matter otherwise.
    otherFundDescription: Yup.string().when('fundSelection', {
        is: 'Other', // alternatively: (val) => val == true
        then: (schema) => schema.defined('Required'),
        otherwise: (schema) => schema,
    }),
    conventionAssessmentPreviousYear: Yup.boolean().default(false),
    conventionAssessmentCurrentYear: Yup.boolean().default(false),
    initiation: Yup.boolean().default(false),
});

// @ts-ignore
type ConvenienceFnsType = ReturnType<typeof DuesForm.convenienceFns>;

class DuesForm extends Component<DuesFormProps, DuesFormState> {
    constructor(props: DuesFormProps, state: DuesFormState) {
        super(props, state);
        this.state = {
            totalOwed: 'uncalculated',
            submissionComplete: false,
            commentLine: EncodeDecode.b64EncodeUnicode(`DK made a mistake and this didn't work this time`),
        }
    }

    componentDidMount() {
        // prevent forms from auto submitting on all inputs
        // Borrowed from https://stackoverflow.com/questions/2020940/how-to-disable-auto-submit-behavior-when-hitting-enter-key
        // Maybe we should remove this on unmount but whatever.
        // Bug: Seems to run twice, not sure why

        // console.log('DuesForm mounted')

        document.addEventListener("keydown", (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
            }
        });
    }

    getInitialFieldValues = () => {
        return {
            givenName: '',
            familyName: '',
            club: '',
            email: '',
            address: '',
            city: '',
            postalCode: '',
            unitNumber: '',
            income: MonthlyIncomeAmount.UNSPECIFIED,
            duesPeriod: 3,
            donation: 0,
            fundSelection: 'Org Fund',
            otherFundDescription: '',
            conventionAssessmentPreviousYear: false,
            conventionAssessmentCurrentYear: false,
            initiation: false,
        } as FormValues;
    }

    totalIsCalculable = (errors: FormikErrors<FormValues>, values: FormValues) => {
        if (!values.province) return false;
        if (values.province.toUpperCase() === 'QC') return !errors.donation;
        return values.income !== MonthlyIncomeAmount.UNSPECIFIED &&
            !errors.conventionAssessmentPreviousYear &&
            !errors.conventionAssessmentCurrentYear &&
            !errors.donation &&
            !errors.income &&
            !errors.duesPeriod &&
            !errors.initiation;
    }

    convenienceFns(values: FormValues, errors: FormikErrors<FormValues>, touched: FormikTouched<FormValues>) {
        const classesFor = (elementType: string, fieldName: keyof FormValues) => {
            const prefix = elementType + '-' + fieldName;
            const error = errors[fieldName];
            return [
                elementType,
                prefix,
                error !== undefined ? `${prefix}--error` : `${prefix}--noerror`,
                error !== undefined ? `${elementType}--error` : `${elementType}--noerror`,
                touched ? `${prefix}--touched` : `${prefix}--untouched`,
                touched ? `${elementType}--touched` : `${elementType}--untouched`,
            ].join(' ');
        };

        return {
            classesFor: classesFor,
            errorSection: (fieldName: keyof FormValues) => {
                type ErrorKey = keyof typeof errors;
                type TouchedKey = keyof typeof touched;

                const error = errors[fieldName as ErrorKey];
                return <div
                    className={classesFor('errorbar', fieldName)}>
                    {error && touched[fieldName as TouchedKey] ? error : null}
                </div>
            }
        }
    }

    handleSubmit = (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {

        console.log('Submitting...');

        setTimeout(() => {
            // alert(JSON.stringify(values, null, 2));
            console.log(JSON.stringify(values, null, 2));
            attemptSendForm(values);
            let commentLine = `Dues. Receipt ${getReceiptCode(values)}`;
            const totalOwed = `${getTotalOwed(values).toFixed(2)}`;

            this.setState({
                submissionComplete: true,
                commentLine: commentLine,
                totalOwed: totalOwed
            });
            setSubmitting(false);
        }, 500);
    }

    private duesVisible(values: FormValues, errors: FormikErrors<FormValues>, touched: FormikTouched<FormValues>) {
        return this.identityComplete(values, errors, touched) &&
            values.province &&
            values.province.toUpperCase() !== 'QC';
    }

    private identityComplete(values: FormValues, errors: FormikErrors<FormValues>, touched: FormikTouched<FormValues>) {
        return requiredIdentityFieldNames.every(ii => !errors[ii])
            && requiredIdentityFieldNames.every(ii => touched[ii] || touched[ii] === undefined)
            && requiredIdentityFieldNames.some(ii => touched[ii]);
    }

    private duesSection(convenienceFns: ConvenienceFnsType) {
        const {classesFor, errorSection} = convenienceFns;
        return (<>

            <div
                className={classesFor('label', 'income')}>
                <label htmlFor="income">Monthly Dues Amount</label>
                <InfoButton
                    msg={"Select your dues amount according to your monthly net income. Dues amounts are determined by the CPC’s constitution."}/>
            </div>
            <div
                className={classesFor('field', 'income')}>
                <Field as="select" name="income" id="income">
                    {monthlyIncomeOptions.map(opt =>
                        <option key={opt.value}
                                value={opt.value}>{opt.description}</option>
                    )}
                </Field>
            </div>
            {errorSection('income')}

            <div
                className={classesFor('label', 'duesPeriod')}>
                <label htmlFor="duesPeriod">Dues Period</label>
                <InfoButton
                    msg={"Dues payments are limited to three months at a time so that dues are not paid too far in advance. This helps ensure that all dues paying members are regularly active in meetings and political work and not just dues payments."}/>
            </div>
            <div
                className={classesFor('field', 'duesPeriod')}>
                <Field as="select" id="duesPeriod" name="duesPeriod">
                    {[0, 1, 2, 3].map(monthCount =>
                        <option key={monthCount}
                                value={monthCount}>{`${monthCount} ${pluralize(monthCount, "month")}`}</option>)}
                </Field>
            </div>
            {errorSection('duesPeriod')}

        </>)
    }

    private additionalContributionSection(values: FormValues, errors: FormikErrors<FormValues>, convenienceFns: ConvenienceFnsType) {
        const {classesFor, errorSection} = convenienceFns;
        // private additionalContributionSection(values: FormValues, errors: FormikErrors<FormValues>, touched: FormikTouched<FormValues>) {
        return (<>

            <div
                className={classesFor('label', 'donation')}>
                <label htmlFor="donation">Additional Contribution ($)</label>
                <InfoButton
                    msg={`If you would like to donate to the CPC in addition to your dues please enter an amount here and select which fundraising drive you would like it applied to. All donations will go towards fulfilling club and Provincial/National Committee quotas.`}/>
            </div>
            <div
                className={classesFor('field', 'donation')}>
                <Field id="donation" name="donation"/>
            </div>
            {errorSection('donation')}

            <div
                className={classesFor('label', 'fundSelection')}>
            </div>
            <div
                className={classesFor('field', 'fundSelection')}>
                <div role="group">
                    <label>
                        <Field type="radio" name="fundSelection" value="Org Fund"/>
                        Org Fund/Central Party Fund
                        <InfoButton
                            msg={`The Org Fund (or Central Party Fund) supports the general operations and political work of the CPC and is usually an active fundraising campaign during the fall until the end of the year.`}/>
                    </label>
                    {/*{ // Spring drive is only displayed for Ontarians.*/}
                    {/*    values.province?.toUpperCase() === 'ON' ? <>*/}
                    {/*        <br/>*/}
                    {/*        <label>*/}
                    {/*            <Field type="radio" name="fundSelection"*/}
                    {/*                   value="Spring Drive"/>*/}
                    {/*            Spring Drive*/}
                    {/*            <InfoButton*/}
                    {/*                msg={`An annual fundraising campaign that runs from March until May.`}/>*/}
                    {/*            /!*msg={`The Spring Drive goes to help support the Communist press and is an active campaign in the spring.`}/>*!/*/}
                    {/*        </label> </> : <></>}*/}
                    <br/>
                    <div>
                        <label>
                            <Field type="radio" name="fundSelection" value="Other"/>
                            Other (please specify)
                        </label>
                        &nbsp;
                        {/*<label><Field id={'otherFundDescription'}*/}
                        {/*              name={'otherFundDescription'}*/}
                        {/*              className={'short-input'}/></label>*/}

                        <label><OtherFundField name={'otherFundDescription'}
                                               className={'short-input'}/></label>
                    </div>
                </div>

            </div>
            {
                errors.fundSelection ?
                    errorSection('fundSelection') :
                    errorSection('otherFundDescription')
            }
        </>);
    }

    // private conventionFields(values: FormValues, errors: FormikErrors<FormValues>, touched: FormikTouched<FormValues>) {

    private conventionFields(convenienceFns: ConvenienceFnsType) {
        const {classesFor, errorSection} = convenienceFns;
        return (<>
            <div
                className={classesFor('label', 'conventionAssessmentPreviousYear')}>
                <label htmlFor="conventionAssessmentPreviousYear">Include Previous Year's Convention Assessment?</label>
                <InfoButton
                    msg={`Convention Assessments or "CAs" are due once a year. Members can pay them at any time but are usually collected towards the end of the year that they are for. A comrades' CA amount is the same as the amount that member pays for one month dues. CAs go towards paying the expenses of Central Conventions of the CPC.`}/>
            </div>
            <div
                className={classesFor('field', 'conventionAssessmentPreviousYear')}>
                <Field type="checkbox" id="conventionAssessmentPreviousYear"
                       name="conventionAssessmentPreviousYear"/>
            </div>
            {errorSection('conventionAssessmentPreviousYear')}

            <div
                className={classesFor('label', 'conventionAssessmentCurrentYear')}>
                <label htmlFor="conventionAssessmentCurrentYear">Include This Year's Convention Assessment?</label>
                <InfoButton
                    msg={`Convention Assessments or "CAs" are due once a year. Members can pay them at any time but are usually collected towards the end of the year that they are for. A comrades' CA amount is the same as the amount that member pays for one month dues. CAs go towards paying the expenses of Central Conventions of the CPC.`}/>
            </div>
            <div
                className={classesFor('field', 'conventionAssessmentCurrentYear')}>
                <Field type="checkbox" id="conventionAssessmentCurrentYear"
                       name="conventionAssessmentCurrentYear"/>
            </div>
            {errorSection('conventionAssessmentCurrentYear')}
        </>);
    }

    // private initiationField(values: FormValues, errors: FormikErrors<FormValues>, touched: FormikTouched<FormValues>) {
    private initiationField(convenienceFns: ConvenienceFnsType) {
        const {classesFor, errorSection} = convenienceFns;
        return (<>

            <div
                className={classesFor('label', 'initiation')}>
                <label htmlFor="initiation">Include initiation Fee?</label>
                <InfoButton
                    msg={"The initiation fee of $1 is to be paid only once after a member has been sworn in by a club or as an at-large member. The initiation should be paid along with a new members' first dues payment."}/>
            </div>
            <div
                className={classesFor('field', 'initiation')}>
                <Field type="checkbox" id="initiation" name="initiation"/>
            </div>
            {errorSection('initiation')}
        </>);
    }

    private identifyFields(convenienceFns: ConvenienceFnsType) {
        const {classesFor, errorSection} = convenienceFns;
        return (<div className='form-field-grid'>
                <div
                    className={classesFor('label', 'givenName')}>
                    <label htmlFor="givenName">First Name</label>
                </div>
                <div
                    className={classesFor('field', 'givenName')}>
                    <Field id="givenName" name="givenName" placeholder="Jane"/>
                    {/*<Field id="givenName" name="givenName" placeholder="Jane" autoComplete="given-name"/>*/}
                </div>
                {errorSection('givenName')}

                <div
                    className={classesFor('label', 'familyName')}>
                    <label htmlFor="familyName">Last Name</label>
                </div>
                <div
                    className={classesFor('field', 'familyName')}>
                    <Field id="familyName" name="familyName" placeholder="Doe"/>
                    {/*<Field id="familyName" name="familyName" placeholder="Doe" autoComplete="family-name"/>*/}
                </div>
                {errorSection('familyName')}

                <div
                    className={classesFor('label', 'club')}>
                    <label htmlFor="club">Club Name</label>
                </div>
                <div
                    className={classesFor('field', 'club')}>
                    <Field id="club" name="club"/>
                </div>
                {errorSection('club')}

                <div
                    className={classesFor('label', 'email')}>
                    <label htmlFor="email">Email</label>
                </div>
                <div
                    className={classesFor('field', 'email')}>
                    <Field
                        id="email"
                        name="email"
                        placeholder="bob@email.com"
                        type="email"
                    />
                </div>
                {errorSection('email')}

                <div
                    className={classesFor('label', 'address')}>
                    <label htmlFor="address">Address</label>
                </div>
                <div
                    className={classesFor('field', 'address')}>
                    <Field id="address" name="address" placeholder="123 Happy St."/>
                </div>
                {errorSection('address')}

                <div
                    className={classesFor('label', 'unitNumber')}>
                    <label htmlFor="unitNumber">Unit Number</label>
                </div>
                <div
                    className={classesFor('field', 'unitNumber')}>
                    <Field id="unitNumber" name="unitNumber" placeholder="If Applicable"/>
                </div>
                {errorSection('unitNumber')}

                <div
                    className={classesFor('label', 'city')}>
                    <label htmlFor="city">City</label>
                </div>
                <div
                    className={classesFor('field', 'city')}>
                    <Field id="city" name="city" placeholder="Calgary"/>
                </div>
                {errorSection('city')}

                <div
                    className={classesFor('label', 'province')}>
                    <label htmlFor="province">Province/Territory Code</label>
                    <InfoButton
                        msg={"The 2-letter abbreviation for your province/territory. " +
                        "One of AB, BC, MB, NB, NL, NT, NS, NU, ON, PE, SK, or YT. " +
                        "Please note that dues for members in QC are to be remitted to the PCQ, not the central party through this form."}/>
                </div>
                <div
                    className={classesFor('field', 'province')}>
                    <Field id="province" name="province" placeholder="ON"/>
                </div>
                {errorSection('province')}

                <div
                    className={classesFor('label', 'postalCode')}>
                    <label htmlFor="postalCode">Postal Code</label>
                </div>
                <div
                    className={classesFor('field', 'postalCode')}>
                    <Field id="postalCode" name="postalCode" placeholder="H0H 0H0"/>
                </div>
                {errorSection('postalCode')}
            </div>
        );
    }

    render() {
        return (
            <div className={'form-body'}>
                <Formik
                    initialValues={this.getInitialFieldValues()}
                    validationSchema={validationSchema}
                    // validateOnChange={true}
                    onSubmit={this.handleSubmit}
                >
                    {({values, errors, touched, isSubmitting}) => {
                        const convenienceFns = this.convenienceFns(values, errors, touched);
                        const identityComplete = this.identityComplete(values, errors, touched);
                        const duesVisible = this.duesVisible(values, errors, touched);
                        return (
                            <>
                                {!this.state.submissionComplete ?
                                    <>
                                        <Form>

                                            {this.identifyFields(convenienceFns)}
                                            <hr/>
                                            <div className='form-field-grid'>
                                                {duesVisible ? this.duesSection(convenienceFns) : <></>}
                                                {identityComplete ? this.additionalContributionSection(values, errors, convenienceFns) : <></>}
                                                {duesVisible ? this.conventionFields(convenienceFns) : <></>}
                                                {duesVisible ? this.initiationField(convenienceFns) : <></>}
                                            </div>

                                            {!identityComplete ? <p>Fill in your identity information to show additional
                                                inputs.</p> : <></>}
                                            <button type="submit"
                                                    className={'submit-button'}
                                                    disabled={!identityComplete || isSubmitting || this.state.submissionComplete}>Submit
                                            </button>

                                        </Form>

                                        <div className={'total-owed'}>
                                            {
                                                identityComplete && this.totalIsCalculable(errors, values) ?
                                                    `Total owed: ${currencyFormatter.format(getTotalOwed(values))}` :
                                                    // "Form is incomplete."
                                                    null
                                            }
                                        </div>
                                    </>
                                    :
                                    // If we're done and submitted, this is what we get.
                                    <FinishedPage commentLine={this.state.commentLine}
                                                  totalOwed={this.state.totalOwed}/>}
                            </>

                        );
                    }}
                </Formik>

            </div>
        );
    }

}

export default DuesForm;
