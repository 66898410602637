import {monthlyDues} from "./monthly-income";
import {EncodeDecode} from "./util";
import {Md5} from "ts-md5";
import {FormValues} from "./form-values";

export function getReceiptCode(values: FormValues, hashLength = 12) {
    if (hashLength < 5) throw new Error('Too short.');

    // We're only including the date up to the hour, so hash collisions are allowed with identical form input within
    // the same hour. Hopefully this saves someone a tiny bit of trouble one day.
    const date = new Date();
    const dateString = `${date.getFullYear()}/${date.getMonth()}/${date.getDay()}/${date.getHours()}`;
    const valuesString = JSON.stringify(values);

    const datehash = Md5.hashAsciiStr(dateString);
    const valueshash = Md5.hashAsciiStr(valuesString);

    // The whole hash is "random" so we can take any piece of it and it'll be random too.
    const firstChunkLength = 4;
    const result = (datehash.substring(0, firstChunkLength) + valueshash.substring(0, hashLength - firstChunkLength)).toUpperCase();

    console.log(`${datehash} ${valueshash} ${result}`);

    return result;
}

export function getReceiptCodeOld(values: FormValues) {
    // TD enforces a message length of 420 chars,
    // "Your message can't have emojis, line breaks, returns, be longer than 420 characters,
    //  or contain any of these special characters: ; or < >."
    const maxLength = 320;

    const {email} = values;

    const date = new Date();
    const dateString = `${date.getFullYear()}/${date.getMonth()}/${date.getDay()}/${date.getHours()}`;
    console.log(dateString);

    let clearText = `${email}/${dateString}`;
    console.log(clearText, clearText.length);
    let cypherText = EncodeDecode.b64EncodeUnicode(clearText);
    console.log(cypherText, cypherText.length);

    if (cypherText.length > maxLength) {
        clearText = `${email}`;
        console.log(clearText, clearText.length);
        cypherText = EncodeDecode.b64EncodeUnicode(clearText);
        console.log(cypherText, cypherText.length);
    }

    if (cypherText.length > maxLength) {
        clearText = `Email too long to encode.`;
        console.log(clearText, clearText.length);
        cypherText = EncodeDecode.b64EncodeUnicode(clearText);
        console.log(cypherText, cypherText.length);
    }

    console.log(cypherText, EncodeDecode.b64DecodeUnicode(cypherText));
    return cypherText;
}

export function getDuesOwed(current: FormValues): number {
    return current.duesPeriod * monthlyDues(current.income);
}

export function getTotalOwed(current: FormValues): number {
    return getDuesOwed(current) +
        +current.donation +
        // Convention assessment is calculated as one month's dues.
        (current.conventionAssessmentPreviousYear ? monthlyDues(current.income) : 0) +
        (current.conventionAssessmentCurrentYear ? monthlyDues(current.income) : 0) +
        (current.initiation ? 1 : 0);
}