import React, {Component} from 'react';
import Swal from 'sweetalert2';
import InfoPic from './info.svg';
import './infoButton.css';

type InfoButtonProps = {
    msg: string;
};
type InfoButtonState = {};

class InfoButton extends Component<InfoButtonProps, InfoButtonState> {
    constructor(props: InfoButtonProps, state: InfoButtonState) {
        super(props, state);
        this.state = {};
    }

    render() {
        return (
            <img className={'info-button'} src={InfoPic} alt={'Info'} onClick={() => {
                Swal.fire({
                    text: this.props.msg,
                    icon: 'info',
                    confirmButtonText: 'OK',
                    iconColor: '#59C5C9',
                    confirmButtonColor: '#E02224',
                })
            }}/>

        );
    }
}

export default InfoButton;
